@import '@/styles/abstracts/variables';
@import '@/styles/abstracts/mixins';

/* Success */
$ff-success-bg-color: #d0dfd8;
$ff-success-text-color: #0a6639;

/* Warning */
$ff-warning-bg-color: $ff-color-orange;
$ff-warning-text-color: $ff-color-blue-cinema;

/* Error */
$ff-error-bg-color: $ff-color-red-rejected;
$ff-error-text-color: $ff-color-white;

/* Error secondary */
$ff-error-secondary-bg-color: $ff-color-red-error-secondary;
$ff-error-secondary-text-color: $ff-color-blue-cinema;

/* Default */
$ff-notification-bg-color: $ff-color-blue-lighter;
$ff-notification-text-color: $ff-color-white;

@mixin setBannerStyle($type: null) {
    @if $type == 'success' {
        background-color: $ff-success-bg-color;
        border-color: $ff-success-text-color;
        color: $ff-success-text-color;
        a {
            color: $ff-success-text-color;
        }
    } @else if $type == 'warning' {
        background-color: $ff-warning-bg-color;
        border-color: $ff-warning-text-color;
        color: $ff-warning-text-color;
        a {
            color: $ff-warning-text-color;
        }
    } @else if $type == 'error' {
        background-color: $ff-error-bg-color;
        border-color: $ff-error-text-color;
        color: $ff-error-text-color;
        a {
            color: $ff-error-text-color;
        }
    } @else if $type == 'error_secondary' {
        background-color: $ff-error-secondary-bg-color;
        border-color: $ff-error-secondary-text-color;
        color: $ff-error-secondary-text-color;
        a {
            color: $ff-error-text-color;
        }
    } @else {
        background-color: $ff-notification-bg-color;
        &:hover {
            background-color: $ff-color-blue-dark-tint;
        }
        border-color: $ff-notification-text-color;
        color: $ff-notification-text-color;
        a {
            color: $ff-notification-text-color;
        }
    }
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
}

.notificationBanner {
    padding: map-get($map: $ff-spacing-sizes, $key: '24') 0;
    padding-left: 1.3rem;
    @include animation(0s, 0.4s, notif-in-top);

    top: 0;

    &.bannerSuccess {
        @include setBannerStyle('success');
    }
    &.bannerWarning {
        @include setBannerStyle('warning');
    }
    &.bannerError {
        @include setBannerStyle('error');
    }
    &.bannerErrorSecondary {
        @include setBannerStyle('error_secondary');
    }
    &.bannerDefault {
        @include setBannerStyle();
    }

    .message {
        @include font(
            $ff-font-p-small-array,
            $ff-line-height-130,
            $ff-font-family-open-sans-semibold,
            map-get($ff-spacing-sizes, '0'),
            false
        );

        @include breakpoint(lg, up) {
            font-family: $ff-font-family-open-sans-semibold;
        }
    }

    .translationUnavailableLink {
        text-decoration: underline;
    }

    .icon {
        transform: scale(0.75);
        cursor: pointer;

        path {
            fill: $ff-color-blue-dark;
        }
    }

    .notificationInnerDiv {
        width: 100%;
    }
}
.arrowIcon {
    margin-left: 7px;
    path {
        stroke: $ff-color-white;
        stroke-width: 4;
    }
}
[dir='rtl'] .arrowIcon {
    transform: rotate(180deg);
}

.customizedArrowIcon {
    margin-left: 7px;
    path {
        stroke-width: 4;
    }
}
[dir='rtl'] .customizedArrowIcon {
    transform: rotate(180deg);
}

.titleDiv {
    padding: 3px 14px;
    background-color: $ff-color-blue-dark-tint;
    border-radius: 40px;
    width: fit-content;
    text-align: center;
    p {
        @include font(
            $ff-font-p-small-array,
            $ff-line-height-130,
            $ff-font-family-open-sans-semibold,
            map-get($ff-spacing-sizes, '0'),
            false
        );

        @include breakpoint(lg, up) {
            font-family: $ff-font-family-open-sans-semibold;
        }
    }
}

@include keyframes(notif-in-top) {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

.clickable {
    cursor: pointer;
}
